@font-face {
    font-family: "GintoBold";
    src: url("../fonts/ABCGintoNormal-Bold.woff2") format("woff2"), url("../fonts/ABCGintoNormal-Bold.woff") format("woff");
}

@font-face {
    font-family: "GintoLight";
    src: url("../fonts/ABCGintoNormal-Light.woff2") format("woff2"), url("../fonts/ABCGintoNormal-Light.woff") format("woff");
}

/*
*
* ==========================================
* CUSTOM UTIL CLASSES
* ==========================================
*
*/
.login,
.image {
    min-height: 100vh;
}

.bg-image {
    background-image: url('login.jpg');
    background-size: cover;
    background-position: center center;
}

.bg-image-curling {
    background-image: url('curling.png');
    background-size: cover;
    background-position: center center;
}

body {
    font-family: GintoLight;
}

h3 {
    font-family: GintoBold;
}

.btn-primary-login {
    background-color: #EF02FA;
    border-color: #EF02FA;
}

    .btn-primary-login:hover {
        background-color: #7F68E4;
        border-color: #7F68E4;
    }

.control-label {
    font-weight: bold;
}

.schoolschaatsen-button {
    background-color: #f8f9fa;
    border-color: #EF02FA;
    color: #EF02FA;
    text-decoration: none;
    text-align: center;
}

    .schoolschaatsen-button:hover {
        background-color: #EF02FA;
        color: white;
    }

.kinderfeestje-button {
    background-color: #f8f9fa;
    border-color: #EF02FA;
    color: #EF02FA;
    text-decoration: none;
    text-align: center;
}

    .kinderfeestje-button:hover {
        background-color: #EF02FA;
        color: white;
    }

.arrangement-button {
    background-color: #f8f9fa;
    border-color: #EF02FA;
    color: #EF02FA;
    text-decoration: none;
    text-align: center;
}

    .arrangement-button:hover {
        background-color: #EF02FA;
        color: white;
    }

.keuze-link {
    font-size: 20px;
}

    .keuze-link:hover {
        text-decoration: none;
    }

    .keuze-link:active {
        text-decoration: none;
    }

    .keuze-link:link {
        text-decoration: none;
    }

    .keuze-link:visited {
        text-decoration: none;
    }
